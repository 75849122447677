<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title> <span>Exportar Envios</span> </v-card-title>
            <v-card-text>
                <div class="row" >
                    <div class="col-md-6" style="display:none">
                         <v-alert border="top" colored-border type="info" elevation="1" >
                            <v-alert class="mt-4" color="white" >
                                Se exportan <b>los primeros 1000 registros</b> a partir de la fecha seleccionada.
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="true" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field 
                                            v-model="dateText" label="Fecha desde" prepend-icon="event" readonly v-bind="attrs" v-on="on" :rules="[v => !!v || 'Debes completar este campo']" 
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" scrollable @input="formatearRango"></v-date-picker>
                                </v-menu>
                                <v-btn class="mr-2 mb-2 " dark color="success" :disabled="!readyFor || loading" @click="exportExcelByConf" >Exportar en XLSX</v-btn>
                                <v-btn class="mr-2 mb-2 " dark color="success" :disabled="!readyFor || loading" @click="exportCSVByConf" >Exportar en CSV</v-btn>
                            </v-alert>
                        </v-alert>
                    </div>
                   <div class="col-md-6" >
                        <v-alert border="top" colored-border type="warning" elevation="1" >
                            <v-alert class="mt-4" color="white" > Se exportan los <b>{{itemsPerPage}} registros de la p&aacute;gina actual. </b></br><small>Puedes cambiar la cantidad en la opción "Registros por página" en la lista de envíos</small></v-alert>
                            <v-btn class="mr-2 mb-2 " dark color="success" @click="exportExcel" >Exportar en XLSX</v-btn>
                             <v-btn  class="mr-2 mb-2 " dark color="success" @click="exportCSV" >Exportar en CSV</v-btn>
                       </v-alert>
                   </div>
               </div>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="blue darken-1" @click="showDialog = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            date: "",
            loading: false,
            dateText: '',
            menu: false,
            itemsPerPage: 100,
        }
    },
    created(){
        this.$parent.$on('open-exportar-envios-dialog', () => {
            this.showDialog = true;
            this.itemsPerPage = this.$parent.selectItemsPerPage;
        });
    },
    methods:{
        exportExcelByConf(){
            var vm = this;
            this.axios({
                url: 'shippings/export/excel?date='+this.dateText+'&timezone='+this.$store.state.passport.timezone,
                method: 'GET',
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar, verifique la fecha ingresada.");
            });
        },
        exportCSVByConf(){
            var vm = this;
            this.axios({
                url: 'shippings/export/csv?date='+this.dateText+'&timezone='+this.$store.state.passport.timezone,
                method: 'GET',
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                type:
                    "text/csv;charset=utf-8;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar, verifique la fecha ingresada.");
            });
        },
        formatearRango()
        {
            if(!this.date.length){
                this.dateText = '';
            }else{
                this.dateText = this.date;
            }
        },
        abrirMensajeSuccess(mensaje) {
            this.$bvToast.toast(mensaje, { title: `Información`, variant: "success", solid: true, toaster: "b-toaster-bottom-center" });
            this.fetchData();
        },
        abrirMensajeError(mensaje) {
            this.$bvToast.toast(mensaje, {
                title: `Error`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-center",
            });
        },
        exportExcel() {
            var headers = [
                "order_id",
                "reference",
                "shipping_number",
                "carrier",
                "marketplace",
                "estado",
                "sku",
                "name",
                "quantity",
                "total",
            ];
            var mapeado = [];
            this.$parent.tableData.forEach((shipping) => {
                if (shipping.products.length) {
                    shipping.products.forEach((product) => {
                        mapeado.push({
                            order_id: shipping.order_id,
                            reference: shipping.order_reference,
                            shipping_number: shipping.shipping_number,
                            carrier: shipping.carrier_name,
                            marketplace: shipping.source.name,
                            estado: shipping.current_state,
                            sku: product.sku,
                            name: product.product,
                            quantity: product.quantity,
                            total: product.total,
                        });
                    });
                }
            });
            this.axios({ 
                url: "excel/from_array", 
                method: "POST", 
                data: { 
                    data: mapeado, 
                    cabeceras: headers
                }, 
                responseType: "blob", 
            })
            .then((response) => {
                const newBlob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
            })
            .catch((error) => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar.");
            });
        },
        exportCSV(){
            var vm = this;
            var headers = [
                "order_id",
                "reference",
                "shipping_number",
                "carrier",
                "marketplace",
                "estado",
                "sku",
                "name",
                "quantity",
                "total",
            ];
            var mapeado = [];
            this.$parent.tableData.forEach((shipping) => {
                if (shipping.products.length) {
                    shipping.products.forEach((product) => {
                        mapeado.push({
                            order_id: shipping.order_id,
                            reference: shipping.order_reference,
                            shipping_number: shipping.shipping_number,
                            carrier: shipping.carrier_name,
                            marketplace: shipping.source.name,
                            estado: shipping.current_state,
                            sku: product.sku,
                            name: product.product,
                            quantity: product.quantity,
                            total: product.total,
                        });
                    });
                }
            });
            this.axios({
                url: 'csv/from_array', method: 'POST', 
                data: { 
                    data: mapeado, 
                    cabeceras: headers
                }, 
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                    type: "text/csv;charset=utf-8;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar.");
            });
        },
    },
    computed:{
        readyFor(){
            return this.dateText != "" ? true : false;
        }
    }
}
</script>