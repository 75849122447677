<template>
    <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span>Modificar Courier - Pedido {{shipment.order_reference}}</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation >
                    <v-select
                        v-model="selectCourier"
                        :items="couriersList"
                        :rules="[v => !!v || 'Debes elegir un courier']"
                        label="Seleccione un courier"
                        required
                      ></v-select>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" text @click="showDialog = false" > Cancelar </v-btn>
                <v-btn color="success" text @click="sendForm" > Guardar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '@/event-bus';
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            shipment: undefined,
            nameRules: [
                v => !!v || 'El Campo es requerido',
            ],
            couriersList: [],
            selectCourier: undefined
        }
    },
    created(){
        var vm = this;
        EventBus.$on('openDialogModificarCourier', (shipment) => {
            vm.shipment = shipment;
            vm.showDialog = true;
        });
    },
    async mounted() {
        await this.fetchCouriers();
    },
    methods: {
        sendForm(){
            var vm = this;
            this.shipment.selectCourier = this.selectCourier;
            this.axios({
                url: 'shipment/change_courier',
                method: 'PUT',
                data: this.shipment
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        async fetchCouriers() {
            var vm = this;
            await this.axios({
                method: "GET",
                url: "shippings/couriers/data_select_input",
            })
            .then((response) => {
                vm.couriersList = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        
    },
}
</script>